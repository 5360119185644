'use client';

import { IconCheck, IconX } from '@tabler/icons-react';
import React, { forwardRef, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '@/components/Text';
import useClickOutside from '@/hooks/useClickOutside';
interface TextAreaInputProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  labelClass?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  withSave?: boolean;
  subscribeClickOutside?: boolean;
  allowCancelWithEsc?: boolean;
  onUpdate?: (_value: string) => void;
  onChange?: (_event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSave?: () => void;
  onCancel?: () => void;
  onClickedOutside?: () => void;
}
export const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>((props, ref) => {
  const {
    label,
    id,
    className,
    error,
    labelClass,
    defaultValue,
    value,
    disabled,
    withSave,
    subscribeClickOutside,
    allowCancelWithEsc = false,
    onUpdate,
    onChange,
    onSave,
    onCancel,
    onClickedOutside,
    ...rest
  } = props;
  const idToUse = id || label?.toLowerCase().replace(' ', '-');
  const componentRef = useRef<HTMLInputElement | null>(null);
  useClickOutside(componentRef, !!subscribeClickOutside, () => {
    onClickedOutside && onClickedOutside();
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate && onUpdate(event.target.value);
    onChange && onChange(event);
  };
  return <div ref={componentRef} className={twMerge(className, 'flex flex-col ')}>
      {label && <Text variant="label" htmlFor={idToUse} className={labelClass}>
          {label}
        </Text>}
      <div className="relative">
        <div className="flex">
          <textarea ref={ref} id={label ? idToUse : undefined} className={`w-full p-2 border border-gray-300 rounded-md text-base`} defaultValue={defaultValue} value={value} disabled={disabled} onKeyDown={event => {
          if (event.key === 'Escape' && allowCancelWithEsc && onCancel) {
            onCancel();
          }
        }} {...rest} onChange={handleInputChange} />
        </div>
        {withSave && <div className="flex justify-end">
            <div className="flex">
              <IconX color="black" onClick={onCancel} />
              <IconCheck color="green" onClick={onSave} />
            </div>
          </div>}
      </div>
      {error && <Text fontColor="error">{error}</Text>}
    </div>;
});
TextAreaInput.displayName = 'TextAreaInput';