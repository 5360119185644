'use client';

import { IconCheck, IconEye, IconEyeOff, IconX } from '@tabler/icons-react';
import React, { forwardRef, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '@/components/Text';
import useClickOutside from '@/hooks/useClickOutside';
interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
  labelClass?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  withSave?: boolean;
  withSuffix?: string;
  narrow?: boolean;
  type?: string;
  subscribeClickOutside?: boolean;
  allowSaveWithEnter?: boolean;
  allowCancelWithEsc?: boolean;
  onUpdate?: (_value: string) => void;
  onChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave?: () => void;
  onCancel?: () => void;
  onClickedOutside?: () => void;
}
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    label,
    id,
    className,
    error,
    labelClass,
    defaultValue,
    value,
    disabled,
    withSave,
    withSuffix,
    narrow,
    type,
    subscribeClickOutside,
    allowSaveWithEnter = false,
    allowCancelWithEsc = false,
    onUpdate,
    onChange,
    onSave,
    onCancel,
    onClickedOutside,
    ...rest
  } = props;
  const idToUse = id || label?.toLowerCase().replace(' ', '-');
  const componentRef = useRef<HTMLInputElement | null>(null);
  useClickOutside(componentRef, !!subscribeClickOutside, () => {
    onClickedOutside && onClickedOutside();
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate && onUpdate(event.target.value);
    onChange && onChange(event);
  };
  return <div className={twMerge(className, 'flex flex-col ')} ref={componentRef}>
        {label && <Text variant="label" htmlFor={idToUse} className={labelClass}>
            {label}
          </Text>}
        <div className="relative">
          <div className="flex">
            <input ref={ref} id={label ? idToUse : undefined} className={`form-input ${!narrow ? 'h-[46px]' : 'h-[35px] text-base p-2'} ${withSave ? 'pr-14' : ''} ${error ? 'border-red-500' : ''} ${withSuffix ? 'ltr:rounded-r-none rtl:rounded-l-none' : ''}`} defaultValue={defaultValue} value={value} disabled={disabled} onKeyDown={event => {
          if (event.key === 'Enter' && allowSaveWithEnter && onSave) {
            onSave();
          }
          if (event.key === 'Escape' && allowCancelWithEsc && onCancel) {
            onCancel();
          }
        }} type={type === 'password' ? showPassword ? 'text' : 'password' : type} {...rest} onChange={handleInputChange} />
            {withSuffix && <div className={`flex items-center justify-center border border-white-light bg-[#eee] px-3 font-semibold ltr:rounded-r-md ltr:border-l-0 rtl:rounded-l-md rtl:border-r-0 dark:border-[#17263c] dark:bg-[#1b2e4b]`}>
                {withSuffix}
              </div>}
          </div>
          {withSave && <div className="absolute flex right-0 top-0 bottom-0 items-center pr-2 z-0">
              <div className="flex">
                <IconX color="black" onClick={onCancel} />
                <IconCheck color="green" onClick={onSave} />
              </div>
            </div>}
          {type === 'password' && <button type="button" onClick={() => {
        setShowPassword(!showPassword);
      }}>
              <div className="absolute flex items-center right-5 top-0 bottom-0">
                {showPassword ? <IconEyeOff size={24} /> : <IconEye size={24} />}
              </div>
            </button>}
        </div>
        {error && <Text fontColor="error">{error}</Text>}
      </div>;
});
TextInput.displayName = 'TextInput';