import * as pdfjsLib from 'pdfjs-dist';

export async function isPdfPasswordProtected(file: File): Promise<boolean> {
  const fileReader = new FileReader();

  return new Promise<boolean>((resolve, reject) => {
    fileReader.onload = async (event: ProgressEvent<FileReader>) => {
      try {
        const typedarray = new Uint8Array(event.target?.result as ArrayBuffer);
        const loadingTask = pdfjsLib.getDocument({
          data: typedarray,
          password: '',
        });
        // PDFJS will trigger this event if the PDF requires a password
        await loadingTask.promise;
        resolve(false); // No password prompt, so PDF is not password-protected
      } catch (error: any) {
        if (error.name === 'PasswordException') {
          resolve(true); // PDF is password-protected
        } else {
          reject(error); // Some other error occurred
        }
      }
    };

    fileReader.onerror = (error: ProgressEvent<FileReader>) => {
      reject(error);
    };

    fileReader.readAsArrayBuffer(file);
  });
}

export async function checkIfAnyFileIsPasswordProtected(
  files: File[]
): Promise<boolean> {
  try {
    // Run isPdfPasswordProtected on each file and wait for all results
    const results = await Promise.all(
      files.map((file) => isPdfPasswordProtected(file))
    );

    // Check if any result is true, indicating at least one file is password-protected
    return results.some((isProtected) => isProtected === true);
  } catch (error) {
    console.error('Error checking files:', error);
    return false; // Return false or handle the error as needed
  }
}
