'use client';

import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import Rselect from 'react-select';
import { Text } from '@/components/Text';
interface SelectOption {
  value: string | number;
  label: string;
}
interface SelectProps extends Omit<React.HTMLProps<HTMLDivElement>, 'defaultValue'> {
  id?: string;
  label?: string;
  defaultValue?: string | number | SelectOption;
  error?: string;
  options: SelectOption[];
  onUpdate: (_value: string | number) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isAllowAddition?: boolean;
  menuIsOpen?: boolean;
  onInputChange?: (_value: string) => void;
  onAdd?: () => void;
  value?: any;
}
export const Select = (props: SelectProps) => {
  const {
    id,
    label,
    defaultValue,
    error,
    options,
    onUpdate,
    className,
    isSearchable = false,
    isClearable = false,
    isAllowAddition = false,
    menuIsOpen,
    onInputChange,
    onAdd,
    value
  } = props;
  const idToUse = id || label?.toLowerCase().replace(' ', '-');
  return <div className={className} data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      {label && <Text variant="label" htmlFor={idToUse}>
          {label}
        </Text>}
      <div className="flex items-center">
        <div className="w-full">
          <Rselect defaultValue={defaultValue} options={options} menuIsOpen={menuIsOpen} isSearchable={isSearchable} isClearable={isClearable} onInputChange={onInputChange} value={value} onChange={option => {
          onUpdate(option?.value === undefined ? '' : option?.value);
        }} styles={{
          control: (styles, state) => ({
            ...styles,
            border: state.isFocused ? '1px solid transparent' : '1px solid #15ABE1',
            borderRadius: '0.375rem',
            padding: '0.25rem',
            '&:hover': {
              borderColor: '#15ABE1'
            },
            fontSize: '1rem'
          }),
          option: (styles, {
            isFocused,
            isSelected
          }) => ({
            ...styles,
            backgroundColor: isSelected ? '#15ABE1' : isFocused ? '#15ABE120' : 'transparent',
            fontSize: '1rem'
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none'
          }),
          valueContainer: styles => ({
            ...styles,
            bgcolor: 'red',
            fontSize: '1rem'
          }),
          menu: styles => ({
            ...styles,
            zIndex: 20
          })
        }} data-sentry-element="Rselect" data-sentry-source-file="Select.tsx" />
        </div>
        {isAllowAddition && <div className="ml-2">
            <button type="button" className="hover:bg-primary hover:rounded-full" onClick={onAdd}>
              <IconPlus size={30} />
            </button>
          </div>}
      </div>
      {error && <Text fontColor="error">{error}</Text>}
    </div>;
};